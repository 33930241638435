<template>
  <div class="outer-frame">
    <div class="main-wrap" ref="mainWrap">
      <router-view></router-view>
    </div>
    <!--<div class="ad-wrap" ref="adWrap">-->
      <!--<div class="ad-img-wrap" @click="goAdUrl">-->
        <!--<img :src="adImg" alt="广告位">-->
      <!--</div>-->
    <!--</div>-->
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o" @click="changeRouter">首页</van-tabbar-item>
      <van-tabbar-item icon="user-o" v-if="!!accessToken" @click="goDetail">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as mqtt from '../api/wsconnect.js'
import * as axios from 'axios'
import * as common from '../api/common'
import * as api from '../api/api'
export default {
  name: 'outer-frame',
  data () {
    return {
      active: 0,
      adImg: '',
      adUrl: '',
      mainH: '',
      adH: ''
    }
  },
  computed: {
    ...mapState({
      accessToken: state => {
        console.log('state.accessToken', state.accessToken)
        return state.accessToken
      }
    })
  },
  methods: {
    changeRouter () {
      if (this.$route.path !== '/index') {
        this.$router.push({
          path: '/index',
          query: {
            ...api.getQuery.apply(this)
          }
        })
      }
    },
    goDetail () {
      console.log(this.$route)
      if (this.$route.path != '/account_detail') {
        this.$router.push({
          path: '/account_detail',
          query: {
            ...api.getQuery.apply(this)
          }
        })
      }
    },
    selectActive () {
      if (this.$route.path == '/account_detail') {
        this.active = 1
      } else {
        this.active = 0
      }
    },
    getAD () {
      let url = ''
      if (/\.test\.|localhost|\d+\.\d+\.\d+\.\d+/.test(location.host)) {
        url = 'https://pa02.yuntingiot.com/api/getAc/608615955352358912'
      } else {
        url = 'https://pa02.yuntingiot.com/api/getAc/608615955352358912'
      }
      axios({
        method: 'get',
        url: url,
        json: true
      })
        .then((response) => {
          // handle success
          if (response.status >= 200 && response.status < 300) {
            if (response.data && response.data.code == 200) {
              let data = response.data.data
              if (data && data.creativeImg) {
                this.adImg = data.creativeImg.creativeUrl || ''
                let screenWidth = window.screen.availWidth
                let adH = screenWidth * data.creativeImg.height / data.creativeImg.width
                this.$refs.adWrap.style.height = adH + 'px'
                this.$refs.mainWrap.style.height = (window.screen.availHeight - adH - 50) + 'px'
              }
              this.adUrl = data && data.landingPage
            }
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
    },
    goAdUrl () {
      if (this.adUrl) window.location.href = this.adUrl
    },
    async getOnlineStatus () {
      try {
        let para = {
          ...api.getPublicPara('v2'),
          sid: localStorage.getItem('sid')
        }
        let { code } = await api.axiosRequests(api.urlList.fenglin.park.get_online_status, para)
        if (code === common.resCode.OK) {
          this.$store.commit('commitOnline', true)
        } else {
          this.$store.commit('commitOnline', false)
        }
      } catch (e) {
        throw e
      }
    }
  },
  mounted () {
    console.log('start client')
    new mqtt.Client()
    console.log('end client')
    this.selectActive ()
    this.$store.commit('commitAccessToken')
    // this.getAD()
    this.getOnlineStatus().catch(e => console.log(e))
    console.log(api.getQuery.apply(this))
    console.log(this.$route)
  },
  beforeDestroy() {
    let client = new mqtt.Client()
    client.client.end(function () {
      mqtt.Client.instance = undefined
    })
  }
}
</script>

<style scoped lang="scss">
@import "../theme/index";
$footer-h: 50px;
$ad-h: 50px;
.outer-frame {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $global-bg-color;
  .ad-wrap{
    box-sizing: border-box;
    width: 100%;
    height: $ad-h;
    /*border: 1px solid lightgray;*/
    -webkit-box-shadow: 0 0 4px lightgray;
    -moz-box-shadow: 0 0 4px lightgray;
    box-shadow: 0 0 4px lightgray;
    .ad-img-wrap{
      width: 100%;
      height: 100%;
      overflow: hidden;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .main-wrap{
    position: absolute;
    top: 0;
    right: 0;
    bottom: $footer-h;
    left: 0;
  }
}
</style>
